import {RoleCode} from '@schemes/role'

const ROLE_LABELS: Record<RoleCode, string> = {
  [RoleCode.Admin]: 'Админ',
  [RoleCode.Teacher]: 'Учитель',
  [RoleCode.Supervisor]: 'Куратор',
  [RoleCode.HeadOfSubject]: 'Руководитель предмета',
  [RoleCode.SubjectLeader]: 'Лидер предмета',
  [RoleCode.TrialTestManager]: 'Менеджер пробных тестов',
}

export const getRoleLabel = (code: RoleCode): string => {
  return ROLE_LABELS[code] ?? code
}
